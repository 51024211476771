import React from 'react';
import {Table} from 'antd';
import {MEMBER_TYPE} from '../Utils/MemberUtil';
import moment from 'moment';
import Button from '../Widgets/Button';
import {STAFF_TYPE} from '../Utils/StaffUtil';
import Anchor from '../Widgets/Anchor';
const appConfig = require('../data.json');

const TableTypes = {
  NORMAL: 0,
  ENTERPRISE: 1,
  MONTHLY: 2,
};

const MemberTable = ({
  members,
  loading,
  onItemClick,
  type = TableTypes.NORMAL,
  pagination,
  onPaging,
  onMonthlySettle,
}) => {
  const columns = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor to={`/member/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      width: 50,
      fixed: 'left',
    },
    {
      title: '顧客名稱',
      render: (record) => {
        let value = record.data ? JSON.parse(record.data) : {};
        return (
          <div>
            <div>{record.name}</div>
            {record.user_type === 'enterprise' && (
              <div style={{color: '#aaa'}}>
                <div>{value.ent_name || '-'}</div>
                <div>{value.gui_number || '-'}</div>
              </div>
            )}
          </div>
        );
      },
      width: 140,
    },

    {
      title: '登入方式',
      render: (_, instance) =>
        instance.social_type === 'default' ? '信箱註冊' : instance.social_type,
      width: 100,
    },
    ...(appConfig.isLiteVersion
      ? []
      : [
          {
            title: '會員群組',
            render: (_, instance) => instance.group_name,
            width: 100,
          },
        ]),
    {
      title: '會員身份',
      render: (_, instance) => {
        return (
          <div>
            <div>{MEMBER_TYPE[instance.user_type]}</div>
            {instance.staff_type && (
              <div style={{color: '#aaa'}}>
                {STAFF_TYPE[instance.staff_type]}
              </div>
            )}
          </div>
        );
      },
      width: 100,
    },
    {
      title: '剩餘點數',
      dataIndex: 'credits',
      width: 100,
    },
    {
      title: '電子信箱',
      dataIndex: 'email',
      width: 200,
    },
    {
      title: '建立時間',
      render: (_, instance) => ` ${instance.created.slice(0, 10)}`,
      width: 100,
    },
  ];

  const columns_monthly = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor to={`/member/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      fixed: 'left',
    },
    {
      title: '公司抬頭/統一編號',
      render: (_, instance) => {
        let value = instance.data ? JSON.parse(instance.data) : {};
        return (
          <div>
            <p>{value.ent_name || '-'}</p>
            <p>{value.gui_number || '-'}</p>
          </div>
        );
      },
    },
    {
      title: '電子信箱',
      dataIndex: 'email',
    },
    ...(typeof onMonthlySettle === 'function'
      ? [
          {
            title: '結算',
            key: 'settle',
            render: (record) => (
              <Button onClick={() => onMonthlySettle(record.id)}>結算</Button>
            ),
          },
        ]
      : []),
    {
      title: '上次結算日',
      key: 'monthly_date',
      dataIndex: 'monthly_date',
      render: (value) => {
        if (value) {
          let overdue = moment(value)
            .add(30, 'days')
            .isSameOrBefore(new Date().toISOString().slice(0, 10));
          return (
            <span style={{color: overdue ? appConfig.colors.error : 'initial'}}>
              {moment(value).format('YYYY-MM-DD')}
            </span>
          );
        }
        return <span style={{color: appConfig.colors.error}}>未產生</span>;
      },
    },
    {
      title: '月結週期（天）',
      key: 'monthly_period',
      dataIndex: 'monthly_period',
    },
    {
      title: '額度',
      key: 'monthly_quota',
      dataIndex: 'monthly_quota',
    },
    {
      title: '登入方式',
      render: (_, instance) => (
        <p style={{marginBottom: 0}}>
          {instance.provider === 'default' ? '信箱註冊' : instance.provider}
        </p>
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      columns={
        type === TableTypes.MONTHLY
          ? columns_monthly
          : // : type === TableTypes.ENTERPRISE
            // ? columns_ent
            columns
      }
      dataSource={members}
      rowKey={(member) => member.id}
      pagination={pagination}
      onChange={onPaging}
      // scroll={{x: 1300}}
    />
  );
};

export default MemberTable;

export {TableTypes};
