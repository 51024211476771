import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import MemberTable, {TableTypes} from '../../Components/MemberTable';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby';
import MemberDialog, {TYPE as DIALOG_TYPE} from '../../Dialogs/MemberDialog';
import {errorHandler} from '../../errors';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
const appConfig = require('../../data.json');

const SelectOptions = [
  {value: 'all', label: '全部', color: '#D7BD23'},
  {value: 'normal', label: '一般會員', color: '#D7BD23'},
  {value: 'enterprise', label: '一般企業', color: '#D7BD23'},
  // { value: "monthly", label: "月結企業", color: "#D7BD23" },
  // { value: "ent_vip_monthly", label: "企業月結預繳", color: "#D7BD23" }
];

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

function Select({options, name, label, extraStyle = {}, filters, onChange}) {
  const value = filters[name];
  return (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
      <div style={{marginRight: 10, width: 120}}>{label}</div>
      <Ant.Select
        value={value}
        style={{width: 120, ...extraStyle}}
        onChange={(nextValue) => onChange({[name]: nextValue})}>
        {options.map((option, idx) => (
          <Ant.Select.Option key={idx} value={option.value}>
            {option.label}
          </Ant.Select.Option>
        ))}
      </Ant.Select>
    </div>
  );
}

export default function MemberList({...props}) {
  const [members, setMembers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');
  const [profile] = useOutlet('user');
  const [openDialog, setOpenDialog] = useState(false);
  const [groups, setGroups] = useState([]);
  const [filters, setFilters] = useState({
    user_type: SelectOptions[0].value,
    group: null,
    search_str: '',
    ...PAGINATION_INIT,
  });

  const changeFilters = (obj) => {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  };

  const getMembers = useCallback(async () => {
    let params = {
      ...(filters.user_type !== 'all' && {user_type: filters.user_type}),
      ...(filters.group && {group: filters.group}),
      // user_type: "normal,vip", //multi query
      search: filters.search_str,
      offset: (filters.current - 1) * filters.pageSize,
      limit: filters.pageSize,
    };

    setLoading(true);

    try {
      let {results, count} = await actions.getMembers(params);
      setMembers(results);
      setTotal(count);
    } catch (err) {
      console.log('err', err);
      Ant.message.error('發生錯誤');
    }
    setLoading(false);
  }, [filters, actions.getMembers]);

  const getGroups = useCallback(async () => {
    try {
      let resp = await actions.getUserGroups({});
      setGroups(resp);
    } catch (err) {
      errorHandler(err, '取得群組清單發生錯誤');
    }
  }, [actions]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4 className="short">會員類別</h4>
            <Ant.Select
              value={filters.user_type}
              onChange={(nextValue) => changeFilters({user_type: nextValue})}
              style={{marginRight: 40}}>
              {SelectOptions.map((option, idx) => (
                <Ant.Select.Option key={idx} value={option.value}>
                  {option.label}
                </Ant.Select.Option>
              ))}
            </Ant.Select>
            {!appConfig.isLiteVersion && (
              <React.Fragment>
                <h4 className="short">會員群組</h4>
                <Ant.Select
                  value={filters.group}
                  onChange={(nextValue) => changeFilters({group: nextValue})}>
                  {[
                    {label: '全部', value: null},
                    ...groups.map((g) => ({label: g.name, value: g.value})),
                  ].map((option, idx) => (
                    <Ant.Select.Option key={idx} value={option.value}>
                      {option.label}
                    </Ant.Select.Option>
                  ))}
                </Ant.Select>
              </React.Fragment>
            )}
          </div>
          <div className="row">
            <h4 className="short">搜尋</h4>
            <Ant.Input.Search
              defaultValue={filters.search_str}
              onSearch={(value) => {
                changeFilters({search_str: value});
              }}
              allowClear
              placeholder="搜尋會員(姓名/電話/抬頭/統編/信箱)"
            />
          </div>
        </div>
        <div className="divider" />
        <div className="row" style={{margin: 0}}>
          <div style={{fontWeight: '500', marginRight: 5}}>共</div>
          <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
          <div style={{fontWeight: '500', marginRight: 5}}>筆</div>
          <div style={{flex: 1}} />
          {profile?.staff_type === 'staff' && (
            <Button
              type="primary"
              onClick={() => setOpenDialog(DIALOG_TYPE.create_user)}
              style={{marginRight: 10}}>
              新增會員
            </Button>
          )}
          <Button
            type="primary"
            onClick={getMembers}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>

      <MemberTable
        type={TableTypes.NORMAL}
        loading={loading}
        members={members}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onPaging={(pagination) =>
          changeFilters({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />

      <MemberDialog
        dialogType={openDialog}
        onClose={() => setOpenDialog(false)}
        onUpdate={getMembers}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
